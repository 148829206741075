import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import NavBar from '../../components/core/navbar';
import Footer from '../../components/core/footer';
import LegalTextTitle from '../../components/legal-text/title';
import OrderLeft from '../../components/order/left';
import OrderRight from '../../components/order/right';
import { useState } from 'react';
import OrderModal from '../../components/order/modal';
import { useTrackableWidth } from '../../hooks/use-trackable-width';

function OrderPage() {
  const { t } = useTranslation('order');

  const [showModal, setShowModal] = useState(true);
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState<string>();
  const { width } = useTrackableWidth();

  return (
    <>
      <div className="text-font-black-2">
        <div className="grid grid-cols-[1fr_54%] h-[100vh] max-[950px]:grid-cols-1">
          <OrderLeft onChangeFile={setFile} onChangeUrl={setUrl} />
          {width > 950 && <OrderRight file={file} url={url} />}
        </div>
        {showModal && <OrderModal onClose={() => setShowModal(false)} />}
      </div>
    </>
  );
}

export default OrderPage;
