import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import clsx from 'clsx';
import { OrderFormData } from '..';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import FormInput from '../../../shared/form/input';
import FormInfo from '../../../shared/form/info';
import FormTextarea from '../../../shared/form/textarea';
import FormCheckbox from '../../../shared/form/checkbox';
import FormUpload from '../../../shared/form/upload';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { urlRegex } from '../../../../utils/utils';
import { useEffect, useState } from 'react';
import Button from '../../../shared/button';
import { useTrackableWidth } from '../../../../hooks/use-trackable-width';
import OrderRight from '../../right';

const schema = yup
  .object()
  .shape({
    url: yup.string().url().required(),
    // url: yup
    //   .string()
    //   .transform((curr, orig) => (orig === '' ? null : curr))
    //   .matches(urlRegex)
    //   .required(),
    file: yup.mixed().nullable(),
    comments: yup.mixed().nullable(),
    consultation: yup.boolean().nullable(),
  })
  .test(
    'atLeastOne',
    'Either file or comments must be provided',
    function (value) {
      const { file, comments } = value;
      return !!file || !!comments;
    }
  )
  .required();

const maxSize = 52428800;

function OrderLeftUpload(props: {
  formData: OrderFormData;
  onNext: (formData: OrderFormData) => void;
  onChangeUrl: (url: string) => void;
  onChangeFile: (file: File) => void;
}) {
  const { t } = useTranslation('order');
  const [maxSizeError, setMaxSizeError] = useState(false);
  const { width } = useTrackableWidth();

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  function onSubmit(values: any) {
    props.onNext({ ...props.formData, ...values });
  }

  const [url, file] = watch(['url', 'file']);

  useEffect(() => {
    if (url && url !== '') {
      props.onChangeUrl(url);
    }
  }, [url]);

  useEffect(() => {
    setValue('url', props.formData.url!);
    setValue('consultation', props.formData.consultation);
    setValue('file', props.formData.file);
    setValue('comments', props.formData.comments);
  }, [props.formData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <FormLabel>{t('uploadForm.url.label')}</FormLabel>
        <FormInput
          placeholder={t('uploadForm.url.placeholder')}
          {...register('url')}
          error={errors.url !== undefined}
        ></FormInput>
        {errors.url && <FormInfo error>{t('uploadForm.url.error')}</FormInfo>}
        <FormInfo>{t('uploadForm.url.note')}</FormInfo>
      </FormGroup>
      <FormGroup>
        <FormLabel note={t('uploadForm.upload.labelS')}>
          {t('uploadForm.upload.label')}
        </FormLabel>

        <Controller
          render={({ field }) => (
            <FormUpload
              value={field.value as any}
              accept="image/png, image/jpeg, image/gif, video/mp4"
              onUpload={(file: File) => {
                setMaxSizeError(false);
                props.onChangeFile(file);
                field.onChange(file);
              }}
              button={t('uploadForm.upload.button')}
              maxSize={maxSize}
              error={errors.file !== undefined}
              onMaxSizeExceeded={() => setMaxSizeError(true)}
            />
          )}
          control={control}
          name="file"
        />
        {maxSizeError && (
          <FormInfo error>
            {t('uploadForm.upload.error', { size: '50MB' })}
          </FormInfo>
        )}
        <FormInfo>{t('uploadForm.upload.note')}</FormInfo>
      </FormGroup>
      <FormGroup>
        <FormLabel note={t('uploadForm.comments.labelS')}>
          {t('uploadForm.comments.label')}
        </FormLabel>
        <FormTextarea
          {...register('comments')}
          placeholder={t('uploadForm.comments.placeholder')}
          rows={4}
          error={errors.comments !== undefined}
        ></FormTextarea>
        <Controller
          render={({ field }) => (
            <FormCheckbox
              checkboxProps={{
                onChange: (ev) => field.onChange(ev.target.checked),
                checked: field.value === true,
                name: 'consultation',
              }}
            >
              <span>{HTMLReactParser(t('uploadForm.consultation.label'))}</span>
            </FormCheckbox>
          )}
          control={control}
          name="consultation"
        />
      </FormGroup>

      {(errors as any)[''] !== undefined && (
        <FormGroup>
          <FormInfo error>{t('uploadForm.uploadOrComment')}</FormInfo>
        </FormGroup>
      )}

      {width <= 950 && <OrderRight url={url} file={file as any} />}

      <Button className="w-full mt-2" color="primary">
        {t('uploadForm.proceed')}
      </Button>
    </form>
  );
}

export default OrderLeftUpload;
