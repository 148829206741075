import axios, { AxiosProgressEvent } from 'axios';
import { UploadDataResponse } from '../interfaces/upload-data-response';

export const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const phoneNumberRegex =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;

export const resizeImage = (src: string, width: number) => {
  return new Promise<{ image: string; width: number }>((resolve, reject) => {
    const img = new Image();

    img.onerror = (error) => {
      reject(error.toString());
    };

    img.onload = () => {
      resolve(resize(img, width));
    };

    img.src = src;
  });
};

export const resizeVideo = (src: string, width: number) => {
  return new Promise<{ image: string; width: number }>((resolve, reject) => {
    const video = document.createElement('video');

    video.onerror = (error) => {
      reject(error);
    };

    video.addEventListener('loadeddata', function () {
      // Wait for the video to be ready
      video.currentTime = 0; // Jump to the first frame
    });

    video.addEventListener('seeked', function () {
      resolve(resize(video, width));
    });

    video.src = src;
  });
};

export const resize = (el: any, width: number) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Get the original image dimensions
  const originalWidth = el.videoWidth || el.width;
  const originalHeight = el.videoHeight || el.height;

  // Calculate the size of the square
  const sideLength = Math.min(originalWidth, originalHeight);

  // Calculate the cropping offsets (to crop from the center)
  const offsetX = (originalWidth - sideLength) / 2;
  const offsetY = (originalHeight - sideLength) / 2;

  // Resize the image if necessary
  if (width < sideLength) {
    const scaleFactor = width / sideLength;
    canvas.width = sideLength * scaleFactor;
    canvas.height = sideLength * scaleFactor;
  } else {
    canvas.width = sideLength;
    canvas.height = sideLength;
  }

  // Draw the cropped and resized image onto the canvas
  ctx!.drawImage(
    el,
    offsetX,
    offsetY,
    sideLength,
    sideLength,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return { image: canvas.toDataURL('image/png'), width: sideLength };
};

export const uploadToS3 = async (
  blob: any,
  signedPostData: UploadDataResponse,
  options: { onUploadProgress?: (progress: AxiosProgressEvent) => void } = {}
): Promise<string> => {
  const formData = new FormData();

  for (const field in signedPostData.fields) {
    formData.append(field, signedPostData.fields[field]);
  }

  formData.append('file', blob);

  await axios.post(signedPostData.url, formData, {
    onUploadProgress: options.onUploadProgress,
  });

  return signedPostData.fields.key;
};
