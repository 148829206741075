import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function ImpressumTitle() {
  const { t } = useTranslation('impressum');
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div ref={containerRef}>
      <motion.h2
        className="text-[57px] font-bold leading-[1.2em] text-center pb-[100px] max-tablet:text-[30px]"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        {t('title')}
      </motion.h2>
    </div>
  );
}

export default ImpressumTitle;
