import clsx from 'clsx';
import styles from './index.module.css';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'default';
  color?: 'default' | 'primary' | 'secondary';
  size?: 'default';
}

function Button(props: ButtonProps) {
  const variant = props.variant || 'default';
  const color = props.color || 'default';
  const size = props.size || 'default';

  let className = clsx(
    styles.button,
    styles[variant],
    color !== 'default' ? styles[props.color!] : undefined,
    size !== 'default' ? styles[props.size!] : undefined,
    props.className
  );

  return (
    <button {...props} className={className}>
      {props.children}
    </button>
  );
}

export default Button;
