import LangSwitcher from '../../shared/lang-switcher';
import HomeHeroQRCode from './qr-code';
import HomeHeroText from './text';

function HomeHeroSection() {
  return (
    <div className="w-full h-screen relative">
      <HomeHeroText />
      {/* max-desktop:left-[-108px] max-tablet:top-[1%] max-tablet:left-[-50px] */}
      <HomeHeroQRCode
        src="/images/hero/v2/50.png"
        className="top-[-104px] left-[-119px]"
        style={{ rotate: 4 }}
        initial={{ x: -150 }}
      />

      <HomeHeroQRCode
        src="/images/hero/v2/45.png"
        className="top-[5px] left-[24%]"
        style={{ rotate: -13 }}
        initial={{ x: -150 }}
      />

      <HomeHeroQRCode
        src="/images/hero/v2/47.png"
        className="top-[73px] left-[52%]"
        style={{ rotate: 7 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/21.png"
        className="top-[-64px] right-[10%] max-[950px]:right-[-60px] max-[950px]:top-[90px] max-[650px]:top-[140px]"
        style={{ rotate: -7 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/27.png"
        className="top-[30%] left-[3%] max-[1300px]:top-[20%]"
        style={{ rotate: 6 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/48.png"
        className="bottom-[32px] left-[3%]"
        style={{ rotate: -25 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/40.png"
        className="bottom-[100px] left-[30%] max-[1300px]:bottom-[200px] max-tablet:bottom-[180px] max-tablet:left-[15%]"
        style={{ rotate: 3 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/32.png"
        className="bottom-[-2px] left-[52%]"
        style={{ rotate: -9 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/3.png"
        className="bottom-[30px] right-[8%] max-[1300px]:bottom-[200px] max-tablet:bottom-[160px]"
        style={{ rotate: -29 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/46.png"
        className="top-[38%] right-[5%] max-[1300px]:top-[30%] max-tablet:bottom-[180px]"
        style={{ rotate: 9 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/v2/44.png"
        className="top-[30%] right-[-192px]"
        style={{ rotate: -16 }}
        initial={{ x: 150 }}
      />
      <LangSwitcher className="absolute right-8 top-8" />
      {/*<HomeHeroQRCode
        src="/images/hero/24-v2.png"
        className="top-[26%] left-[-170px] max-desktop:left-[-153px] max-tablet:left-[-78px]  max-tablet:top-[50%] max-tablet:hidden"
        style={{ rotate: -33 }}
        initial={{ x: -150 }}
      />

      <HomeHeroQRCode
        src="/images/hero/27-v2.png"
        className="bottom-[24%] right-[-169px] max-desktop:right-[-131px] max-desktop:bottom-[46%] max-tablet:bottom-[50%] max-tablet:right-[-83px]"
        style={{ rotate: 14 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/42-v2.png"
        className="top-[10%] right-[2%]  max-desktop:right-[22px] max-desktop:top-[24%]  max-tablet:right-[-22px]"
        style={{ rotate: -7 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/48-v2.png"
        className="bottom-[-84px] left-[-146px] max-desktop:left-[-120px] max-desktop:bottom-[4%] max-tablet:left-[-12px]  max-tablet:bottom-[3%]"
        style={{ rotate: -25 }}
        initial={{ y: 150 }}
      /> */}

      {/* <HomeHeroQRCode
        src="/images/hero/1-v2.png"
        className="top-[-3px] left-[43%] max-desktop:top-[11%] max-tablet:top-[25%]"
        style={{ rotate: 7 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/2-v2.png"
        className="top-[8%] left-[8%] max-desktop:top-[16%] max-tablet:top-[27%] max-tablet:left-[10px]"
        style={{ rotate: -13 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/5-v2.png"
        className="top-[-37px] left-[-148px] max-desktop:left-[-108px] max-tablet:top-[1%] max-tablet:left-[-50px]"
        style={{ rotate: 21 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/7-v2.png"
        className="bottom-[-134px] left-[17%] max-desktop:bottom-[2%] max-tablet:hidden"
        style={{ rotate: 3 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/9-v2.png"
        className="bottom-[-139px] right-[-51px] max-desktop:bottom-[1%] max-desktop:right-[22px]  max-tablet:bottom-[4%]  max-tablet:right-[-21px]"
        style={{ rotate: -6 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/12-v2.png"
        className="top-[-138px] left-[69%] max-desktop:top-[-46px]  max-tablet:top-[1%]  max-tablet:left-auto  max-tablet:right-[-19px]"
        style={{ rotate: 20 }}
        initial={{ y: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/14-v2.png"
        className="bottom-[16%] left-[5%] max-desktop:bottom-[27%]  max-tablet:left-[-12px] max-tablet:bottom-[30%]"
        style={{ rotate: 24 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/15-v2.png"
        className="bottom-[3%] left-[43%] max-desktop:bottom-[19%] max-tablet:bottom-[18%]"
        style={{ rotate: -9 }}
        initial={{ y: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/16-v2.png"
        className="bottom-[14%] left-[75%] max-desktop:bottom-[25%] max-desktop:left-auto max-desktop:right-[-22px] max-tablet:bottom-[34%] max-tablet:right-[-50px]"
        style={{ rotate: -29 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/24-v2.png"
        className="top-[26%] left-[-170px] max-desktop:left-[-153px] max-tablet:left-[-78px]  max-tablet:top-[50%] max-tablet:hidden"
        style={{ rotate: -33 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/26-v2.png"
        className="top-[-166px] left-[13%] max-desktop:top-[-128px] max-tablet:top-[-2%] max-tablet:left-[37%]"
        style={{ rotate: 0 }}
        initial={{ x: -150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/27-v2.png"
        className="bottom-[24%] right-[-169px] max-desktop:right-[-131px] max-desktop:bottom-[46%] max-tablet:bottom-[50%] max-tablet:right-[-83px]"
        style={{ rotate: 14 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/42-v2.png"
        className="top-[10%] right-[2%]  max-desktop:right-[22px] max-desktop:top-[24%]  max-tablet:right-[-22px]"
        style={{ rotate: -7 }}
        initial={{ x: 150 }}
      />
      <HomeHeroQRCode
        src="/images/hero/48-v2.png"
        className="bottom-[-84px] left-[-146px] max-desktop:left-[-120px] max-desktop:bottom-[4%] max-tablet:left-[-12px]  max-tablet:bottom-[3%]"
        style={{ rotate: -25 }}
        initial={{ y: 150 }}
      />
      */}
    </div>
  );
}

export default HomeHeroSection;
