import clsx from 'clsx';
import React, { useRef } from 'react';
import FormInput from '../input';
import Button from '../../button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value?: File | null;
  error?: boolean;
  onUpload: (file: File) => void;
  maxSize: number;
  onMaxSizeExceeded: (maxSize: number) => void;
  placeholder?: string;
  accept?: string;
  button?: string;
}

function FormUpload(props: Props) {
  const fileInputRef = useRef<any>(null);

  function onChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.size > props.maxSize) {
        return props.onMaxSizeExceeded(props.maxSize);
      }

      props.onUpload(event.target.files[0]);
    }
  }

  function triggerUpload() {
    fileInputRef.current!.click();
  }

  return (
    <div {...props} className={clsx(props.className, 'flex')}>
      <div className="flex-1 mr-3 cursor-pointer" onClick={triggerUpload}>
        <FormInput
          className="w-full pointer-events-none bg-white"
          error={props.error}
          placeholder={props.placeholder}
          value={props.value ? props.value.name : undefined}
          disabled
        ></FormInput>
      </div>

      <Button type="button" onClick={triggerUpload}>
        {props.button}
      </Button>

      <input
        type="file"
        className="hidden"
        onChange={onChange}
        accept={props.accept}
        ref={fileInputRef}
      />
    </div>
  );
}

export default FormUpload;
