import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

function HomeAestheticAI(props: {
  title: string;
  subtitle: string;
  image: string;
}) {
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div
      ref={containerRef}
      className="bg-gradient-to-br from-[#7CEBFF] to-[#8465FF] rounded-[24px] p-[30px]"
    >
      <motion.div
        className="flex flex-col justify-between h-full max-desktop:justify-start"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5 }}
      >
        <div className="text-white font-bold max-desktop:mb-4">
          <div className="text-[50px] leading-[1.2em] pb-1 max-desktop:text-[30px] max-tablet:text-[24px]">
            {props.title}
          </div>
          <div className="text-[20px] leading-[1.2em]">{props.subtitle}</div>
        </div>
        <div className="flex justify-end max-desktop:justify-center max-tablet:justify-end">
          <img
            src={props.image}
            alt="ai"
            className="w-[243px] max-tablet:w-[194px]"
          />
        </div>
      </motion.div>
    </div>
  );
}

export default HomeAestheticAI;
