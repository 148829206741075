// import en from './en/en';

// export default {
//   interpolation: { escapeValue: false },
//   lng: 'en',
//   resources: {
//     en,
//   },
// };

import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedLangs = ['en', 'de'];

export type SupportedLang = 'en' | 'de';

export const getPropByLang = (
  lang: SupportedLang,
  obj: any,
  prop: string = 'name'
) => {
  const postfix =
    lang !== 'en'
      ? lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase()
      : '';
  return obj[prop + postfix];
};

const loadAllFiles = (lang: SupportedLang) => {
  const jsonFiles =
    lang === 'en'
      ? (require as any).context(`./en`, false, /\.json$/)
      : (require as any).context(`./de`, false, /\.json$/);
  const allJsonData: any = {};

  jsonFiles.keys().forEach((fileName: string) => {
    const jsonKey = fileName.replace(/\.\/|\.json/g, '');
    allJsonData[jsonKey] = jsonFiles(fileName);
  });

  return allJsonData;
};

const i18nBuilder = (options?: InitOptions) =>
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      // lng: 'en',
      fallbackLng: 'en',
      supportedLngs: supportedLangs,
      resources: {
        en: loadAllFiles('en'),
        de: loadAllFiles('de'),
      },
      interpolation: {
        escapeValue: false,
      },

      ...options,
    });

export default i18nBuilder;
