import clsx from 'clsx';
import React from 'react';

export interface FormTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const FormTextarea = React.forwardRef((props: FormTextareaProps, ref?: any) => {
  let className = clsx(
    'border outline-none p-2 px-3 text-font-black-2 rounded-[5px] placeholder:text-[#B0B8C8]',
    props.error ? 'border-red-500 bg-red-100' : 'border-[#B0B8C8]',
    props.className
  );

  return (
    <textarea
      {...{ ...props, error: undefined }}
      className={className}
      ref={ref}
    >
      {props.children}
    </textarea>
  );
});

export default FormTextarea;
