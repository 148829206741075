import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import clsx from 'clsx';
import { OrderFormData } from '..';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import FormInput from '../../../shared/form/input';
import FormInfo from '../../../shared/form/info';
import FormTextarea from '../../../shared/form/textarea';
import FormCheckbox from '../../../shared/form/checkbox';
import FormUpload from '../../../shared/form/upload';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadToS3, urlRegex } from '../../../../utils/utils';
import { useEffect, useState } from 'react';
import Button from '../../../shared/button';
import { getUploadData, submitOrder } from '../../../../api/order';
import { useParams } from 'react-router-dom';
import { useTrackableWidth } from '../../../../hooks/use-trackable-width';
import OrderRight from '../../right';
import Notification from '../../../shared/notification';
import { isAxiosError } from 'axios';

const schema = yup
  .object()
  .shape({
    companyName: yup.string().required(),
    contactName: yup.string().required(),
    email: yup.string().email().required(),
    // privacy: yup.bool().oneOf([true], 'Field must be checked').required(),
    terms: yup.bool().oneOf([true], 'Field must be checked').required(),
  })
  .required();

function OrderLeftInfo(props: {
  formData: OrderFormData;
  onNext: (formData: OrderFormData) => void;
  onBack: (formData: OrderFormData) => void;
}) {
  const { t, i18n } = useTranslation('order');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { id } = useParams();
  const { width } = useTrackableWidth();

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  useEffect(() => {
    setValue('companyName', props.formData.companyName!);
    setValue('contactName', props.formData.contactName!);
    setValue('email', props.formData.email!);
  }, [props.formData]);

  async function onSubmit(values: any) {
    setLoading(true);
    setError(undefined);
    try {
      const formData: OrderFormData = { ...props.formData, ...values };

      let filePath: string | undefined = undefined;

      if (formData.file) {
        const uploadData = await getUploadData(
          id!,
          formData.file!.type,
          formData.file!.name
        );
        filePath = await uploadToS3(formData.file!, uploadData);
      }

      await submitOrder(
        i18n.language,
        id!,
        { ...formData, file: undefined },
        filePath
      );

      props.onNext(formData);
    } catch (err) {
      console.log('failed to submit order', err);
      if (isAxiosError(err) && err.status === 429) {
        setError(t('infoForm.tooManyRequests'));
      } else {
        setError(t('infoForm.error'));
      }
    }

    setLoading(false);
  }

  function onBack() {
    props.onBack({ ...props.formData, ...getValues() } as any);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <FormLabel>{t('infoForm.name.label')}</FormLabel>
        <FormInput
          placeholder={t('infoForm.name.placeholder')}
          {...register('companyName')}
          error={errors.companyName !== undefined}
          disabled={loading}
        ></FormInput>
        {errors.companyName && (
          <FormInfo error>{t('infoForm.name.error')}</FormInfo>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('infoForm.contact.label')}</FormLabel>
        <FormInput
          placeholder={t('infoForm.contact.placeholder')}
          {...register('contactName')}
          error={errors.contactName !== undefined}
          disabled={loading}
        ></FormInput>
        {errors.contactName && (
          <FormInfo error>{t('infoForm.contact.error')}</FormInfo>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('infoForm.email.label')}</FormLabel>
        <FormInput
          type="email"
          placeholder={t('infoForm.email.placeholder')}
          {...register('email')}
          error={errors.email !== undefined}
          disabled={loading}
        ></FormInput>
        {errors.email && <FormInfo error>{t('infoForm.email.error')}</FormInfo>}
        <FormInfo>{t('infoForm.email.note')}</FormInfo>
      </FormGroup>
      {width <= 950 && (
        <OrderRight
          url={props.formData.url}
          file={props.formData.file as any}
        />
      )}
      {/* <FormGroup className="items-start mb-2">
        <Controller
          render={({ field }) => (
            <FormCheckbox
              checkboxProps={{
                onChange: (ev) => field.onChange(ev.target.checked),
                checked: field.value === true,
                name: 'privacy',
                disabled: loading,
              }}
            >
              <span>{HTMLReactParser(t('infoForm.privacy.label'))}</span>
            </FormCheckbox>
          )}
          control={control}
          name="privacy"
        />

        {errors.privacy && (
          <FormInfo error>{t('infoForm.privacy.error')}</FormInfo>
        )}
      </FormGroup> */}
      <FormGroup className="items-start">
        <Controller
          render={({ field }) => (
            <FormCheckbox
              checkboxProps={{
                onChange: (ev) => field.onChange(ev.target.checked),
                checked: field.value === true,
                name: 'terms',
                disabled: loading,
              }}
            >
              <span>{HTMLReactParser(t('infoForm.terms.label'))}</span>
            </FormCheckbox>
          )}
          control={control}
          name="terms"
        />
        {errors.terms && <FormInfo error>{t('infoForm.terms.error')}</FormInfo>}
      </FormGroup>

      {error && <Notification color="danger">{error}</Notification>}

      <Button className="w-full mt-1" color="secondary" disabled={loading}>
        {t('infoForm.proceed')}
      </Button>
      <Button
        type="button"
        className="w-full mt-2 mb-4"
        onClick={onBack}
        disabled={loading}
      >
        {t('infoForm.back')}
      </Button>

      <FormGroup>
        <FormInfo>{HTMLReactParser(t('infoForm.disclaimer'))}</FormInfo>
      </FormGroup>
    </form>
  );
}

export default OrderLeftInfo;
