import { useEffect, useState } from 'react';
import HomeAestheticSection from '../../components/home/aesthetic';
import HomeDisruptiveSection from '../../components/home/disruptive';
import HomeHeroSection from '../../components/home/hero';
import HomeQrCodesSection from '../../components/home/qr-codes';
import HomeStickyTextSection from '../../components/home/sticky-text';
import HomeTeamSection from '../../components/home/team';
import Footer from '../../components/core/footer';

function HomePage() {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 2000);
  }, []);
  return (
    <div className="overflow-x-clip">
      <HomeHeroSection />
      {load && (
        <>
          <HomeStickyTextSection />
          <HomeDisruptiveSection />
          <HomeAestheticSection />
          <HomeQrCodesSection />
          <HomeTeamSection />
          <Footer />
        </>
      )}
    </div>
  );
}

export default HomePage;
