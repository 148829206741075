import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import clsx from 'clsx';
import { OrderFormData } from '..';
import FormGroup from '../../../shared/form/group';
import FormLabel from '../../../shared/form/label';
import FormInput from '../../../shared/form/input';
import FormInfo from '../../../shared/form/info';
import FormTextarea from '../../../shared/form/textarea';
import FormCheckbox from '../../../shared/form/checkbox';
import FormUpload from '../../../shared/form/upload';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { urlRegex } from '../../../../utils/utils';
import { useEffect, useState } from 'react';
import Button from '../../../shared/button';

function OrderLeftSubmitted(props: { formData: OrderFormData }) {
  const { t } = useTranslation('order');

  return (
    <div>
      <div className="mb-6">
        {HTMLReactParser(t('submitted.text1', { email: props.formData.email }))}
      </div>
      <div className="mb-8">{HTMLReactParser(t('submitted.text2'))}</div>

      <a href="/" className="block">
        <Button className="w-full">{t('submitted.back')}</Button>
      </a>
    </div>
  );
}

export default OrderLeftSubmitted;
