import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import clsx from 'clsx';
import NavBar from '../../core/navbar';
import { useEffect, useRef, useState } from 'react';
import { resizeImage, resizeVideo } from '../../../utils/utils';
import { generatePreview } from '../../../api/order';
import { useParams } from 'react-router-dom';

let savedPreview: string | undefined;

function OrderRight(props: { file?: File; url?: string }) {
  const timeout = useRef<any>();
  const [preview, setPreview] = useState<string | undefined>(savedPreview);
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation('order');

  useEffect(() => {
    if (props.file) {
      uploadImage(props.file, props.url);
    }
  }, [props.file]);

  useEffect(() => {
    if (props.file) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        uploadImage(props.file!, props.url);
      }, 500);
    }
  }, [props.url]);

  async function uploadImage(file: File, url?: string) {
    try {
      const fileUrl = URL.createObjectURL(file);

      let { image, width } =
        file.type === 'video/mp4'
          ? await resizeVideo(fileUrl, 800)
          : await resizeImage(fileUrl, 800);

      URL.revokeObjectURL(fileUrl);

      const qrCode = await generatePreview(
        id!,
        props.url || 'https://aesthetic-tech.com/',
        image,
        width
      );

      setPreview(`data:image/png;base64,${qrCode}`);
      savedPreview = `data:image/png;base64,${qrCode}`;
    } catch (err) {
      console.log('failed to generate preview', err);
    }
  }

  return (
    <div className={styles.container}>
      <div className="flex-1 flex justify-center items-center max-[950px]:flex-col">
        <div
          className="hidden max-[950px]:flex px-3 py-[0.565rem] w-full cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="font-medium mr-auto">{t('preview.title')}</div>
          <img
            src="/images/caret-down.svg"
            className={clsx(expanded ? 'rotate-180' : undefined)}
          />
        </div>

        <div
          className={clsx(
            'max-w-[448px] max-[950px]:mt-0 max-[950px]:hidden max-[950px]:px-3 max-[950px]:pb-3',
            expanded ? 'max-[950px]:!block' : undefined
          )}
        >
          <div className="text-[40px] leading-[54px] text-center font-bold mb-8 max-[950px]:hidden">
            {t('preview.title')}
          </div>

          <div className="flex justify-center mb-6 max-[950px]:my-3">
            <img
              className="w-full max-w-[388px]"
              src={preview || '/images/preview/default.png'}
            />
          </div>

          <div className="text-sm text-center mb-12 max-[950px]:mb-5">
            {t('preview.notScannable')}
          </div>
          <div className="text-justify">
            {HTMLReactParser(t('preview.text'))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderRight;
