import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home';
import ImpressumPage from './pages/impressum';
import PrivacyPolicyPage from './pages/privacy';
import {
  homeRoute,
  impressumRoute,
  orderRoute,
  privacyRoute,
  termsRoute,
} from './utils/routes';
import TermsOfServicePage from './pages/terms';
import OrderPage from './pages/order';

function App() {
  return (
    <Routes>
      <Route path={homeRoute} element={<HomePage />} />
      <Route path={impressumRoute} element={<ImpressumPage />} />
      <Route path={privacyRoute} element={<PrivacyPolicyPage />} />
      <Route path={termsRoute} element={<TermsOfServicePage />} />
      <Route path={orderRoute} element={<OrderPage />} />
    </Routes>
  );
}

export default App;
