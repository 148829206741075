import { motion } from 'framer-motion';

export interface HomeTeamItem {
  image: string;
  title: string;
  subtitle: string;
  url: string;
}

function HomeTeamItemEl(props: { item: HomeTeamItem }) {
  return (
    <div className="max-desktop:flex max-desktop:flex-col max-desktop:items-center">
      <a
        className="block mb-3"
        href={props.item.url}
        target="_blank"
        rel="noreferrer"
      >
        <motion.img
          className="w-[200px] h-[200px]"
          initial={{
            scale: 1,
          }}
          whileHover={{ scale: 0.95 }}
          transition={{ type: 'spring' }}
          src={props.item.image}
        />
      </a>
      <div className="text-[18px] font-bold leading-[1.5em] text-center">
        {props.item.title}
      </div>
      <div className="text-[16px] leading-[1.5em] text-center">
        {props.item.subtitle}
      </div>
    </div>
  );
}

export default HomeTeamItemEl;
