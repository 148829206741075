import { useTranslation } from 'react-i18next';
import Modal from '../../shared/modal';
import HTMLReactParser from 'html-react-parser';
import Button from '../../shared/button';

function OrderModal(props: { onClose: () => void }) {
  const { t } = useTranslation('order');

  const sections = (
    t('modal.sections', { returnObjects: true }) as {
      title: string;
      text: { label: string; text: string }[];
    }[]
  ).map((obj, i) => {
    const content = obj.text.map((text) => (
      <li className="ml-5">
        <span className="font-medium pr-1">{text.label}</span>
        <span>{text.text}</span>
      </li>
    ));

    return (
      <div>
        <div className="text-lg font-bold">{obj.title}</div>
        {i === 0 && <ol className="list-outside list-decimal">{content}</ol>}
        {i === 1 && <ul className="list-outside list-disc">{content}</ul>}
      </div>
    );
  });

  return (
    <Modal
      className="w-full max-w-[770px]"
      onClose={props.onClose}
      disableClickOutside
    >
      <div className="text-[40px] font-bold leading-[60px] mb-5 max-tablet:text-[32px] max-tablet:leading-[40px]">
        {HTMLReactParser(t('modal.title'))}
      </div>
      <div className="mb-6">{HTMLReactParser(t('modal.text'))}</div>
      {sections[0]}
      <div className="mt-3 mb-5">{t('modal.consulting')}</div>
      {sections[1]}
      <div className="my-6">{HTMLReactParser(t('modal.disclaimer'))}</div>
      <div className="text-sm my-6">{HTMLReactParser(t('modal.cookie'))}</div>
      <div className="flex justify-end">
        <Button className="w-[219px] max-tablet:w-full" onClick={props.onClose}>
          {t('modal.proceed')}
        </Button>
      </div>
    </Modal>
  );
}

export default OrderModal;
