import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function HomeDisruptiveTitle() {
  const { t } = useTranslation('home');
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div className="pt-6" ref={containerRef}>
      <motion.h2
        className="text-[57px] leading-[1.2em] text-center font-bold max-tablet:text-[30px]"
        initial={{
          opacity: 0,
        }}
        animate={inView ? { opacity: 1 } : {}}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        {t('disruptive.title')}
      </motion.h2>
    </div>
  );
}

export default HomeDisruptiveTitle;
