import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  error?: boolean;
}

function FormInfo(props: Props) {
  let className = clsx(
    'text-sm',
    props.className,
    props.error ? 'text-red-500' : 'text-[#B0B8C8]'
  );

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default FormInfo;
