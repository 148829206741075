import { Link } from 'react-router-dom';
import { homeRoute } from '../../../utils/routes';
import LangSwitcher from '../../shared/lang-switcher';

function NavBar() {
  return (
    <div className="flex justify-center py-5">
      <div className="max-w-[1200px] w-full mx-6 mb-20 flex justify-start items-center">
        <Link to={homeRoute} className="w-[144px]">
          <img src="/images/footer/Logo.png" alt="logo" className="w-[144px]" />
        </Link>

        <LangSwitcher className="ml-auto" />
      </div>
    </div>
  );
}

export default NavBar;
