import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

function HomeAestheticOpen(props: {
  title: string;
  image: string;
  image2: string;
}) {
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div
      ref={containerRef}
      className="relative rounded-[24px] p-[30px] overflow-hidden"
    >
      <img
        src={props.image}
        alt="background"
        className="absolute top-0 left-0 w-full h-full object-fill"
      />

      <div className="flex justify-center mb-12 mt-6  max-desktop:mb-4 max-tablet:mb-14">
        <img
          src={props.image2}
          alt="qr"
          className="w-[240px] relative z-10 max-desktop:w-full max-tablet:w-[160px]"
        />
      </div>

      <motion.div
        className="relative text-[50px] leading-[1.2em] font-bold z-10 max-desktop:text-[30px] max-tablet:text-[24px]"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5 }}
      >
        {props.title}
      </motion.div>
    </div>
  );
}

export default HomeAestheticOpen;
