import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

export interface HomeDisruptiveItem {
  image: string;
  icon: string;
  title: string;
  text: string;
}

function HomeDisruptiveItemEl(props: { item: HomeDisruptiveItem }) {
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div ref={containerRef}>
      <motion.div
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5 }}
      >
        <div className="overflow-hidden rounded-[24px] mb-12">
          <motion.img
            initial={{
              scale: 1,
            }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5 }}
            src={props.item.image}
          />
        </div>
        <img
          className="w-[50px] h-[50px] mb-5"
          alt="icon"
          src={props.item.icon}
        />
        <div className="text-[30px] leading-[1.2em] font-bold mb-5 max-tablet:text-[24px]">
          {props.item.title}
        </div>
        <div className="text-[18px] leading-[1.5em] text-[#34393D]">
          {props.item.text}
        </div>
      </motion.div>
    </div>
  );
}

export default HomeDisruptiveItemEl;
