import clsx from 'clsx';
import { motion, useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

function HomeQrCodeList(props: {
  n: number;
  max: number;
  speed: string;
  direction: 'l' | 'r';
}) {
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setAnimate(true);
      }, 1000);
    }
  }, [inView]);

  // animate-infinite-scroll-${props.direction}
  const items = (prefix: string) => (
    <div
      className={clsx(
        'flex space-x-6 flex-shrink-0 ',
        inView ? ` mr-6 mb-6` : undefined,
        animate ? `animate-infinite-scroll-${props.direction}` : undefined
      )}
      style={{ animationDuration: props.speed }}
    >
      {Array.from(Array(props.max).keys()).map((i) => (
        <motion.img
          key={'qr-list-' + prefix + props.n + i}
          src={`/images/qr-codes/${props.n}/v2/${i + 1}.png`}
          className="w-[268px] cursor-pointer"
          initial={{
            opacity: 0.5,
          }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
      ))}
    </div>
    // <div ref={containerRef}>
    //   <div
    //     className={clsx(
    //       inView
    //         ? `flex space-x-6 flex-shrink-0 animate-infinite-scroll-${props.direction} mr-6 mb-6`
    //         : undefined
    //     )}
    //   >
    //     {Array.from(Array(props.max).keys()).map((i) => (
    //       <motion.img
    //         key={'qr-list-' + prefix + props.n + i}
    //         src={`/images/qr-codes/${props.n}/${i + 1}.png`}
    //         className="w-[268px] cursor-pointer"
    //         initial={{
    //           opacity: 0.5,
    //         }}
    //         whileHover={{ opacity: 1 }}
    //         transition={{ duration: 0.5 }}
    //       />
    //     ))}
    //   </div>
    //   <motion.div
    //     initial={{
    //       opacity: 0,
    //       y: 150,
    //     }}
    //     animate={inView ? { y: 0, opacity: 1 } : {}}
    //     transition={{ duration: 0.5 }}
    //   >

    //   </motion.div>
    // </div>
  );
  return (
    <div ref={containerRef}>
      <motion.div
        className="flex flex-nowrap"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5 }}
      >
        {items('0')}
        {items('1')}
      </motion.div>
    </div>
  );
}

export default HomeQrCodeList;
