import { useTranslation } from 'react-i18next';
import HomeAestheticAI from './ai';
import HomeAestheticOpen from './open';
import HomeAestheticPill from './pill';
import HomeAestheticTitle from './title';
import HomeAestheticUse from './use';

function HomeAestheticSection() {
  const { t } = useTranslation('home');
  const items = t('aesthetic.items', { returnObjects: true }) as {
    title: string;
    image: string;
    image2: string;
    subtitle: string;
  }[];

  return (
    <div className="px-[30px] py-[100px] max-tablet:px-[20px] max-tablet:py-[100px] ">
      <div className="px-[40px] py-[120px] max-tablet:px-[0] max-tablet:py-[120px]">
        <div className="flex flex-col items-center">
          <div className="w-full max-w-[720px] mb-24">
            <HomeAestheticPill />
            <HomeAestheticTitle />
          </div>
          <div className="grid grid-cols-2 gap-[24px] max-w-[1200px] max-tablet:grid-cols-1 max-tablet:gap-[30px]">
            <HomeAestheticUse title={items[0].title} image={items[0].image} />
            <HomeAestheticOpen
              title={items[1].title}
              image={items[1].image}
              image2={items[1].image2}
            />
            <HomeAestheticAI
              title={items[2].title}
              subtitle={items[2].subtitle}
              image={items[2].image}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAestheticSection;
