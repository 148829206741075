import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HomeTeamItemEl, { HomeTeamItem } from './item';

function HomeTeamItems() {
  const { t } = useTranslation('home');
  const items = (
    t('team.items', { returnObjects: true }) as HomeTeamItem[]
  ).map((item, i) => <HomeTeamItemEl key={'team-item-' + i} item={item} />);

  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div ref={containerRef}>
      <motion.div
        className="flex justify-center"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
      >
        <div className="w-full max-w-[1200px] relative">
          <img
            src="/images/team/background.svg"
            className="absolute left-0 right-0 top-1/2 -translate-y-1/2 max-desktop:hidden"
            alt="background"
          />
          <div className="relative flex justify-between z-10 max-desktop:grid max-desktop:grid-cols-2 max-desktop:gap-y-4  max-tablet:grid-cols-1  max-tablet:gap-y-8">
            {items}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default HomeTeamItems;
