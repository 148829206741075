import { useTranslation } from 'react-i18next';
import HomeQrCodeList from './list';

function HomeQrCodesSection() {
  const { t } = useTranslation('home');

  return (
    <div className="relative overflow-hidden max-h-screen">
      <HomeQrCodeList n={1} max={12} speed="140s" direction="l" />
      <HomeQrCodeList n={2} max={12} speed="200s" direction="r" />
      <HomeQrCodeList n={3} max={12} speed="300s" direction="r" />
      <HomeQrCodeList n={3} max={12} speed="400s" direction="l" />
      <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none bg-gradient-to-b from-transparent to-[#E9EFF5]"></div>
      <div className="absolute bottom-[100px] left-0 right-0 flex justify-center ">
        <div className="rainbow text-[57px] font-black max-tablet:text-[30px]">
          {t('qrCodes.title')}
        </div>
      </div>
    </div>
  );
}

export default HomeQrCodesSection;
