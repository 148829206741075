import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function HomeTeamTitle() {
  const { t } = useTranslation('home');
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div ref={containerRef}>
      <motion.h2
        className="text-[57px] leading-[1.2em] text-center font-bold mb-24 cursor-pointer max-tablet:text-[30px]"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.3 }}
      >
        {t('team.title')}
      </motion.h2>
    </div>
  );
}

export default HomeTeamTitle;
