import axios from 'axios';
import { OrderFormData } from '../components/order/left';
import myAxios from '../utils/my-axios';
import { UploadDataResponse } from '../interfaces/upload-data-response';

export const getUploadData = async (
  token: string,
  contentType: string,
  fileName: string
): Promise<UploadDataResponse> => {
  const { data } = await myAxios.get(`/upload-data`, {
    params: { contentType, fileName },
    headers: { 'x-api-key': token },
  });

  return data;
};

export const generatePreview = async (
  token: string,
  content: string,
  image: string,
  width: number
): Promise<string> => {
  const { data } = await myAxios.post(
    `/generate-preview`,
    {
      content,
      image,
      width,
    },
    { headers: { 'x-api-key': token } }
  );

  return data;
};

export const submitOrder = async (
  lang: string,
  token: string,
  formData: OrderFormData,
  filePath?: string
): Promise<void> => {
  const { data } = await myAxios.post(
    `/order`,
    {
      filePath,
      url: formData.url,
      comments:
        !formData.comments || formData.comments === ''
          ? undefined
          : formData.comments,
      consultation: formData.consultation,
      companyName: formData.companyName,
      contactName: formData.contactName,
      email: formData.email,
      lang,
    },
    { headers: { 'x-api-key': token } }
  );

  return data;
};
