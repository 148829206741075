import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import NavBar from '../../core/navbar';
import { useState } from 'react';
import OrderLeftUpload from './upload';
import OrderLeftInfo from './info';
import PageIndicator from '../../shared/page-indicator';
import OrderLeftSubmitted from './submitted';
import { homeRoute } from '../../../utils/routes';
import { Link } from 'react-router-dom';
import LangSwitcher from '../../shared/lang-switcher';

export interface OrderFormData {
  url?: string;
  file?: File;
  comments?: string;
  consultation?: boolean;
  companyName?: string;
  contactName?: string;
  email?: string;
}

function OrderLeft(props: {
  onChangeUrl: (url: string) => void;
  onChangeFile: (file: File) => void;
}) {
  const { t } = useTranslation('order');
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState<OrderFormData>({});

  function onNext(data: OrderFormData) {
    setFormData(data);
    setPage(page + 1);
    window.scrollTo(0, 0);
  }

  function onBack(data: OrderFormData) {
    setFormData(data);
    setPage(page - 1);
  }

  return (
    <div className="pt-3 flex flex-col">
      <div className="p-6 flex justify-start items-center max-[950px]:pb-12">
        <Link to={homeRoute} className="w-[144px]">
          <img src="/images/footer/Logo.png" alt="logo" className="w-[144px]" />
        </Link>
        <LangSwitcher className="ml-auto" />
      </div>

      <div className="flex flex-col items-center justify-center flex-1 px-6">
        <div className="w-full max-w-[448px]">
          <div className="text-[40px] font-bold leading-[54px] max-[500px]:text-[32px] max-[500px]:leading-[43px]">
            {HTMLReactParser(t('title'))}
          </div>
          <div className="text-lg mb-8 max-[500px]:text-base">
            {HTMLReactParser(t('subtitle'))}
          </div>

          {page === 0 && (
            <OrderLeftUpload
              formData={formData}
              onNext={onNext}
              onChangeFile={props.onChangeFile}
              onChangeUrl={props.onChangeUrl}
            />
          )}
          {page === 1 && (
            <OrderLeftInfo
              formData={formData}
              onNext={onNext}
              onBack={onBack}
            />
          )}
          {page === 2 && <OrderLeftSubmitted formData={formData} />}
        </div>
      </div>
      <PageIndicator
        page={page}
        pageClasses={[
          'bg-gradient-to-r from-[#FFDC72] to-[#E64A67]',
          'bg-gradient-to-r from-[#7CEBFF] to-[#7856FF]',
        ]}
        className="mt-auto py-6"
      />
    </div>
  );
}

export default OrderLeft;
