import clsx from 'clsx';
import React from 'react';
import styles from './index.module.css';

export interface FormCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const FormCheckbox = React.forwardRef(
  (
    props: {
      children: any;
      checkboxProps?: FormCheckboxProps;
      className?: string;
      error?: boolean;
    },
    ref?: any
  ) => {
    return (
      <label className={clsx(styles.label, props.className)}>
        <input
          type="checkbox"
          className={styles.checkbox}
          {...{ ...(props.checkboxProps || {}) }}
          ref={ref}
        />
        <span></span>
        <span>{props.children}</span>
      </label>
    );
  }
);

export default FormCheckbox;
