import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function HomeHeroText(props: {}) {
  const { t } = useTranslation('home');
  const variants = t('hero.text.variants', { returnObjects: true }) as {
    t: string;
    c: string;
  }[];
  const counter = useRef<number>(0);
  const [currentVariant, setCurrentVariant] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      if (counter.current < variants.length - 1) {
        counter.current++;
      } else {
        counter.current = 0;
      }
      setCurrentVariant(counter.current);
    }, 3000);

    return () => clearInterval(id);
  }, [variants.length]);

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] font-bold text-[84px] flex overflow-hidden leading-[1.3em]  max-tablet:text-[40px]  max-tablet:leading-[1.2em]">
      <div className="mr-6">{t('hero.text.new')}</div>

      <motion.div
        className="w-[392px] max-tablet:w-[200px]"
        key={currentVariant}
        animate={{ opacity: 1, y: 0 }}
        initial={{
          opacity: 0,
          y: '100%',
          color: variants[currentVariant].c,
        }}
        transition={{ duration: 0.5 }}
      >
        {variants[currentVariant].t}
      </motion.div>
    </div>
  );
}

export default HomeHeroText;
