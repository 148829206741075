import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  page: number;
  pageClasses: string[];
}

function PageIndicator(props: Props) {
  const els = props.pageClasses.map((pageClass: string, i) => {
    return i === props.page ? (
      <div className={clsx('w-[68px] h-[10px] rounded-[5px]', pageClass)}></div>
    ) : (
      <div className="w-[10px] h-[10px] rounded-[5px] bg-[#B0B8C8] bg-opacity-50"></div>
    );
  });

  return (
    <div
      {...props}
      className={clsx(props.className, 'flex space-x-4 justify-center')}
    >
      {els}
    </div>
  );
}

export default PageIndicator;
