import clsx from 'clsx';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import HTMLReactParser from 'html-react-parser';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

function HomeStickyTextSection() {
  const containerRef = useRef<any>(null);
  const { scrollY } = useScroll();
  const { t } = useTranslation('home');

  const variants = t('stickyText', { returnObjects: true }) as string[];
  const [highlighted, setHighlighted] = useState(0);

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const scrollY = containerRef.current
      ? containerRef.current.getBoundingClientRect().y
      : 0;

    if (scrollY > -100) {
      setHighlighted(0);
    } else if (scrollY > -700) {
      setHighlighted(1);
    } else {
      setHighlighted(2);
    }
  });

  return (
    <div ref={containerRef} className="h-[1800px] relative">
      <div className="sticky top-[200px] my-[280px] flex justify-center max-desktop:top-[100px] max-tablet:top-[10px]">
        <div
          className={clsx(
            styles.text,
            'sticky max-w-[1000px] text-center text-[40px] font-bold leading-[1.8em] max-desktop:px-8 max-tablet:text-[26px]'
          )}
        >
          {variants.map((text, i) => (
            <span
              key={'sticky-' + i}
              className={clsx(
                'mr-2',
                i !== highlighted
                  ? 'opacity-[0.2] !text-[#212427]'
                  : styles.highlight
              )}
            >
              {HTMLReactParser(text)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeStickyTextSection;
