import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  large?: boolean;
}

function LangSwitcher(props: Props) {
  const { t, i18n } = useTranslation('common');

  const langs = t('langs', { returnObjects: true });

  const els = Object.keys(langs).map((lang) => (
    <div
      className={clsx(
        'px-3 border-r border-[#212427] text-[#212427] first:pl-0 last:border-r-0 last:pr-0',
        i18n.language === lang ? 'font-bold' : 'cursor-pointer'
      )}
      onClick={() => (i18n.language !== lang ? changeLang(lang) : {})}
    >
      {(langs as any)[lang]}
    </div>
  ));

  function changeLang(lang: string) {
    i18n.changeLanguage(lang);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div {...props} className={clsx(props.className, 'flex')}>
      {els}
    </div>
  );
}

export default LangSwitcher;
