export interface ImpressumItem {
  label: string;
  value: string;
}

function ImpressumItemEl(props: { item: ImpressumItem }) {
  return (
    <div className="text-[18px] leading-[1.5em]">
      <span className="mr-2">
        <span className="text-[#7E5DFF]">{props.item.label}</span>:
      </span>
      <span>{props.item.value}</span>
    </div>
  );
}

export default ImpressumItemEl;
