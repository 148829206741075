import HomeTeamItems from './items';
import HomeTeamTitle from './title';

function HomeTeamSection() {
  return (
    <div className="px-[30px] pt-[160px] pb-[120px] bg-[#E7EEF4] rounded-b-[80px] max-desktop:rounded-b-[60px] max-tablet:rounded-b-[40px]">
      <HomeTeamTitle />
      <HomeTeamItems />
    </div>
  );
}

export default HomeTeamSection;
