import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

function HomeDisruptivePill() {
  const { t } = useTranslation('home');
  const pillRef = useRef<any>();
  const pillInView = useInView(pillRef, { once: true });

  return (
    <div className="flex justify-center" ref={pillRef}>
      <motion.div
        className="px-[16px] py-[8px] bg-black bg-opacity-[0.1] rounded-[20px]"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={pillInView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        {t('disruptive.pill')}
      </motion.div>
    </div>
  );
}

export default HomeDisruptivePill;
