import { useTranslation } from 'react-i18next';
import NavBar from '../../components/core/navbar';
import ImpressumItemEl, {
  ImpressumItem,
} from '../../components/impressum/item';
import ImpressumTitle from '../../components/impressum/title';
import Footer from '../../components/core/footer';

function ImpressumPage() {
  const { t } = useTranslation('impressum');

  const items = (t('items', { returnObjects: true }) as ImpressumItem[]).map(
    (item) => <ImpressumItemEl key={'impressum-' + item.label} item={item} />
  );

  return (
    <div>
      <NavBar />
      <div className="flex justify-center items-start min-h-screen px-6">
        <div className="w-full max-w-[1200px] px-[40px] py-[120px] rounded-[72px] bg-[#E7EEF4] mb-20 max-desktop:rounded-[56px] max-tablet:rounded-[30px] max-tablet:px-[20px] max-tablet:py-[80px]">
          <ImpressumTitle />
          <div className="space-y-5">{items}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ImpressumPage;
