import { useTranslation } from 'react-i18next';
import HomeDisruptiveItemEl, { HomeDisruptiveItem } from './item';
import HomeDisruptivePill from './pill';
import HomeDisruptiveTitle from './title';

function HomeDisruptiveSection() {
  const { t } = useTranslation('home');
  const items = (
    t('disruptive.items', { returnObjects: true }) as HomeDisruptiveItem[]
  ).map((item, i) => (
    <HomeDisruptiveItemEl key={'dis-item-' + i} item={item} />
  ));

  return (
    <div className="px-[30px] py-[100px] max-tablet:px-[20px] max-tablet:py-[100px]">
      <div className="bg-[#E7EEF4] rounded-[72px] px-[40px] py-[120px] max-desktop:rounded-[56px] max-tablet:px-[20px] max-tablet:py-[80px] max-tablet:rounded-[30px]">
        <div className="flex flex-col items-center">
          <div className="w-full max-w-[720px] mb-24">
            <HomeDisruptivePill />
            <HomeDisruptiveTitle />
          </div>
          <div className="grid grid-cols-3 gap-[30px] max-w-[1200px] max-desktop:grid-cols-1">
            {items}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDisruptiveSection;
