import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
  required?: boolean;
  note?: string;
  // showRequiredInfo?: boolean;
}

function FormLabel(props: Props) {
  // const { t } = useTranslation('common');

  let className = clsx(
    'font-medium text-lg max-[500px]:text-base',
    props.className
  );

  return (
    <p {...{ ...props, showRequiredInfo: undefined }} className={className}>
      {props.children}
      {props.required && <span className="text-primary">*</span>}
      {props.note && (
        <span className="ml-1 font-normal text-sm text-[#B0B8C8]">
          {props.note}
        </span>
      )}
      {/* {props.showRequiredInfo && (
        <span className="ml-auto text-xs text-primary">
          {t('requiredInfo')}
        </span>
      )} */}
    </p>
  );
}

export default FormLabel;
