import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

function HomeAestheticUse(props: { title: string; image: string }) {
  const containerRef = useRef<any>();
  const inView = useInView(containerRef, { once: true });

  return (
    <div
      ref={containerRef}
      className="bg-[#F5F5F5] rounded-[24px] col-span-full px-20 py-12 max-desktop:px-6 max-desktop:py-32 max-tablet:py-10"
    >
      <motion.div
        className="grid grid-cols-2 justify-center items-center gap-[50px] max-tablet:grid-cols-1"
        initial={{
          opacity: 0,
          y: 150,
        }}
        animate={inView ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 0.5 }}
      >
        <img
          className="max-w-[100%] justify-self-end"
          alt="qrcode"
          src={props.image}
        />
        <div className="text-[50px] leading-[1.2em] font-bold max-desktop:text-[30px] max-tablet:text-[24px]">
          {props.title}
        </div>
      </motion.div>
    </div>
  );
}

export default HomeAestheticUse;
